<template>
  <div class="main">
    <Navbar />
    <section class="main-hero">
      <img class="brand-logo" src="@/assets/highpoint/logo.svg" />
<!--      <p class="main-title">RektProof</p>-->
      <p class="main-subtitle">analysis - education - discussion </p>
    </section>
    <div class="main-footer">
      <a @click="disclaimer = true">disclaimer</a>
    </div>
    <DisclaimerModal :active="disclaimer" @close="disclaimer = false" />
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from "@/components/Navbar2.vue";
import DisclaimerModal from "@/components/DisclaimerModal";
import Mobilemenu from "@/components/Mobilemenu.vue";

export default {
  name: "Home",
  components: {
    Navbar,
    DisclaimerModal,
    Mobilemenu,
  },
  data() {
    return {
      disclaimer: false,
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 76px);
}
.main {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  font-family: "Work Sans", sans-serif;
  //background: linear-gradient(
  //  180deg,
  //  var(--background-color-gradient-secondary) 0%,
  //  var(--background-color-gradient-tertiary) 100%
  //);
  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-image: url("~@/assets/highpoint/dark_texture_3.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}
.main-hero {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .brand-logo {
    width: 300px;
  }
  .main-title {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    letter-spacing: 0.1em;
    color: white;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .main-subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.1em;
    color: white;
  }
}
.main-footer {
  display: flex;
  justify-content: center;
  padding-bottom: 32px;
  z-index: 2;
  a {
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    text-align: center;
    letter-spacing: 0.1em;
    text-decoration-line: underline;
    color: #ffffff;
  }
}
</style>
