<template>
  <div :class="{ 'is-active': active }" class="modal">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="close">
          <span @click="closeModal()" class="has-text-white">X</span>
        </div>
        <div class="content">
          <slot></slot>
        </div>
      </section>
<!--      <footer class="modal-card-foot is-flex is-justify-content-center">-->
<!--        <button class="button is-dark" >Understood</button>-->
<!--      </footer>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'MaterialLightbox',
  props: ['active'],
  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-background {
  background-color: rgba(10, 10, 10, 0.2);
}
.modal-card {
  border: 2px solid white;
  //border-image-source: linear-gradient(123.08deg, #FFFFFF 23.19%, rgba(255, 255, 255, 0) 88.33%);
  //background: linear-gradient(123.08deg, #FFFFFF 23.19%, rgba(255, 255, 255, 0) 88.33%), linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  backdrop-filter: blur(20px);
  border-radius: 20px;
  .modal-card-body {
    position: relative;
    //padding: 46px 106px 30px 106px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    backdrop-filter: blur(20px);
    border-radius: 20px;
    .close {
      position: absolute;
      top: 16px;
      right: 20px;
      cursor: pointer;
      font-size: 24px;
      z-index: 11;
    }
    .content {
      //background: #FFFFFF;
      border-radius: 20px;
      //padding: 14px 82px 25px 82px;
      color: hsl(0deg, 0%, 29%);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
@media screen and (min-width: 960px) {
  .modal-content, .modal-card {
    //width: 944px;
    width: calc(100vw - 100px);
    height: calc(100vh - 100px);
  }
}
</style>
